import React from "react";
import {
    Create,
    CreateProps,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EditProps,
    EmailField,
    List,
    required,
    ResourceProps,
    Show,
    ShowProps,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin";

import { format } from "date-fns";

const resourceName = "user";
const options = { label: "Users" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <TextInput source="firstName" />,
];

const ResourceList: React.FC = (props) => {
    const GenderField = ({ source }: { source: string }) => {
        const record = useRecordContext();
        if (!record) return null;

        if (record[source] != null) {
            const text = genderToString(record[source].type);
            return <span>{text}</span>;
        }

        return <span>-</span>;
    };
    const AgeField = ({ source, label }: { source: string; label: string }) => {
        const record = useRecordContext();

        if (!record) return null;

        if (record[source] != null) {
            const text = calculateAge(record[source]);

            return (
                <TextField
                    source={source}
                    label={label}
                    record={{ [source]: text }}
                />
            );
        }

        return (
            <TextField
                source={source}
                label={label}
                record={{ [source]: "-" }}
            />
        );
    };

    const genderToString = (type: Number) => {
        switch (type) {
            case 0:
                return "Man";
            case 1:
                return "Woman";
            case 2:
                return "Nonbinary";
            default:
                return "-";
        }
    };

    const calculateAge = (birthDate: String) => {
        const today = new Date();
        const dobParts = birthDate.split("-");
        const dobYear = parseInt(dobParts[0], 10);
        const dobMonth = parseInt(dobParts[1], 10) - 1; // Месяцы начинаются с 0
        const dobDay = parseInt(dobParts[2], 10);

        let age = today.getFullYear() - dobYear;
        const monthDiff = today.getMonth() - dobMonth;

        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDay)) {
            age--;
        }

        return age;
    };

    const DatingPreferencesField = ({
        source,
        label,
    }: {
        source: string;
        label: string;
    }) => {
        const record = useRecordContext();

        if (!record) return null;

        if (record[source] != null) {
            if (record[source][0] != null) {
                const text = genderToString(record[source][0].type);
                return (
                    <TextField
                        source={source}
                        label={label}
                        record={{ [source]: text }}
                    />
                );
            }
        }

        return (
            <TextField
                source={source}
                label={label}
                record={{ [source]: "-" }}
            />
        );
    };

    const DaitingPreferenceField = ({
        source,
        label,
    }: {
        source: string;
        label: string;
    }) => {
        const record = useRecordContext();

        if (!record) return null;

        if (record[source] != null) {
            if (record[source].payPreferences != null) {
                const text = daitingPreferenceToString(
                    record[source].payPreferences
                );

                return (
                    <TextField
                        source={source}
                        label={label}
                        record={{ [source]: text }}
                    />
                );
            }
        }

        return (
            <TextField
                source={source}
                label={label}
                record={{ [source]: "-" }}
            />
        );
    };

    const daitingPreferenceToString = (type: Number) => {
        switch (type) {
            case 0:
                return "I would like to be treated on the date";
            case 1:
                return "I'm down to cover the costs for the date";
            case 2:
                return "Let’s go Dutch on the date and split the bill";
            default:
                return "-";
        }
    };

    const LogisticsField = ({
        source,
        label,
    }: {
        source: string;
        label: string;
    }) => {
        const record = useRecordContext();

        if (!record) return null;

        if (record[source] != null) {
            if (record[source].logistics != null) {
                const text = logisticsToString(record[source].logistics);

                return (
                    <TextField
                        source={source}
                        label={label}
                        record={{ [source]: text }}
                    />
                );
            }
        }

        return (
            <TextField
                source={source}
                label={label}
                record={{ [source]: "-" }}
            />
        );
    };

    const logisticsToString = (type: Number) => {
        switch (type) {
            case 0:
                return "Meet at the location";
            case 1:
                return "I want to be picked up";
            case 2:
                return "Send driver";
            case 3:
                return "I'm willing to pick you up";
            default:
                return "-";
        }
    };

    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <DateField source="createdAt" label="Created At" />
                <DateField source="updatedAt" label="Last modified date" />
                <TextField source="firstName" label="First name" />
                <TextField source="lastName" label="Last name" />
                <TextField source="phone" label="Phone" />
                <TextField source="email" label="Email" />
                <DateField source="dateOfBirth" label="Date of birth" />
                <GenderField source="gender" />
                <TextField source="ambassador" label="Ambassador" />
                <TextField source="location.city" label="City" />
                <TextField source="location.coutry" label="Coutry" />
                <TextField source="location.lng" label="Longitude" />
                <TextField source="location.lat" label="Latitude" />
                <AgeField source="dateOfBirth" label="Age" />
                <DatingPreferencesField
                    source="datingPreferences"
                    label="Intrested In"
                />
                <DaitingPreferenceField
                    source="datingInfo"
                    label="Dating Preference"
                />
                <LogisticsField
                    source="datingInfo"
                    label="Logistic Preference"
                />
                <TextField
                    source="subscriptionType"
                    label="Subscription Type"
                />
                <TextField source="referralCount" label="Referral Count" />
                <TextField
                    source="referralConverted"
                    label="Referral Converted"
                />
                <TextField source="rating" label="Popularity Score" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const ResourceCreate: React.FC<CreateProps> = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="firstName" validate={[required()]} />
                <TextInput source="lastName" validate={[required()]} />
                <TextInput source="phone" />
                <TextInput source="email" />
            </SimpleForm>
        </Create>
    );
};

const ResourceEdit: React.FC<EditProps> = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="id" disabled />
                <TextInput source="firstName" validate={[required()]} />
                <TextInput source="lastName" validate={[required()]} />
                <TextInput source="email" />
                <TextInput source="phone" />
            </SimpleForm>
        </Edit>
    );
};
const ResourceShow: React.FC<ShowProps> = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="email" />
                <TextField source="phone" />
            </SimpleShowLayout>
        </Show>
    );
};

ResourceList.displayName = `${resourceName}List`;
ResourceCreate.displayName = `${resourceName}Create`;
ResourceEdit.displayName = `${resourceName}Edit`;
ResourceShow.displayName = `${resourceName}Show`;

export default {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    show: ResourceShow,
    icon,
    options,
} as Omit<ResourceProps, "name">;
