import React from "react";
import {
    Datagrid,
    EditButton,
    List,
    ResourceProps,
    TextField,
    TextInput,
    SimpleForm,
    SelectInput,
    required,
    Create,
    CreateProps,
    Edit,
    EditProps,
} from "react-admin";

const resourceName = "purchase";
const options = { label: "Purchases" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <TextInput source="type" />,
];

const ResourceList: React.FC = (props) => {
    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <TextField source="purchaseId" label="Purchase Id" />
                <TextField source="platform" />
                <TextField source="type" />
                <TextField source="amount" />
                <TextField source="currency" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const ResourceCreate: React.FC<CreateProps> = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput
                    source="purchaseId"
                    label="Purchase Id"
                    validate={[required()]}
                />
                <SelectInput
                    source="platform"
                    choices={[
                        { id: "ios", name: "ios" },
                        { id: "android", name: "android" },
                    ]}
                    validate={[required()]}
                />
                <SelectInput
                    source="type"
                    choices={[
                        { id: "ambassador", name: "Ambassador" },
                        { id: "crown", name: "Crown" },
                        { id: "boost", name: "Boost" },
                        {
                            id: "month-subscription",
                            name: "Month Subscription",
                        },
                        { id: "year-subscription", name: "Year Subscription" },
                    ]}
                    validate={[required()]}
                />
                <TextInput source="amount" />
                <TextInput source="currency" />
            </SimpleForm>
        </Create>
    );
};

const ResourceEdit: React.FC<EditProps> = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput
                    source="purchaseId"
                    label="Purchase Id"
                    validate={[required()]}
                />
                <SelectInput
                    source="platform"
                    choices={[
                        { id: "ios", name: "ios" },
                        { id: "android", name: "android" },
                    ]}
                    validate={[required()]}
                />
                <SelectInput
                    source="type"
                    choices={[
                        { id: "ambassador", name: "Ambassador" },
                        { id: "crown", name: "Crown" },
                        { id: "boost", name: "Boost" },
                        {
                            id: "month-subscription",
                            name: "Month Subscription",
                        },
                        { id: "year-subscription", name: "Year Subscription" },
                    ]}
                    validate={[required()]}
                />
                <TextInput source="amount" />
                <TextInput source="currency" />
            </SimpleForm>
        </Edit>
    );
};

ResourceList.displayName = `${resourceName}List`;
ResourceCreate.displayName = `${resourceName}Create`;
ResourceEdit.displayName = `${resourceName}Edit`;

export default {
    list: ResourceList,
    create: ResourceCreate,
    edit: ResourceEdit,
    icon,
    options,
} as Omit<ResourceProps, "name">;
