import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ResourceProps,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin";

const resourceName = "Invites";
const options = { label: "Invites" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <TextInput source="place" />,
];

const ResourceList: React.FC = (props) => {
    const StatusField = ({ source }: { source: string }) => {
        const record = useRecordContext();
        if (!record) return null;

        if (record[source] != null) {
            const text = statusToString(record[source]);
            return <span>{text}</span>;
        }

        return <span>-</span>;
    };

    const statusToString = (type: Number) => {
        switch (type) {
            case 0:
                return "Sent";
            case 1:
                return "Approve";
            case 2:
                return "Reject";
            default:
                return "-";
        }
    };

    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <TextField source="date" label="Date ID" />
                <DateField source="createdAt" label="Created At" />
                <TextField source="sender" label="Sender ID" />
                <TextField source="receiver" label="Receiver ID" />
                <StatusField source="status" />
                <TextField source="place" label="Place Id" />
            </Datagrid>
        </List>
    );
};

ResourceList.displayName = `${resourceName}List`;

export default {
    list: ResourceList,
    icon,
    options,
} as Omit<ResourceProps, "name">;
