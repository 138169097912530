import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ResourceProps,
    TextField,
    TextInput,
} from "react-admin";

const resourceName = "payment";
const options = { label: "Payments" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <TextInput source="type" />,
];

const ResourceList: React.FC = (props) => {
    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <DateField source="createdAt" label="Created At" />
                <DateField source="udatedAt" label="Udated At" />
                <TextField source="user" label="User Id" />
                <TextField source="type" />
                <TextField source="platform" />
                <TextField source="transactionId" label="Transaction Id" />
                <TextField source="amount" />
                <TextField source="currency" />
            </Datagrid>
        </List>
    );
};

ResourceList.displayName = `${resourceName}List`;

export default {
    list: ResourceList,
    icon,
    options,
} as Omit<ResourceProps, "name">;
