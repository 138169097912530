import React from "react";
import {
    Create,
    CreateProps,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    EditProps,
    EmailField,
    List,
    required,
    ResourceProps,
    Show,
    ShowProps,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
    SelectInput,
} from "react-admin";

const resourceName = "report";
const options = { label: "Reports" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <SelectInput
        source="type"
        choices={[
            { id: 0, name: "Fake profile" },
            { id: 1, name: "Scam" },
            { id: 2, name: "Sexual services" },
            { id: 3, name: "Sale or mention of drugs" },
            { id: 4, name: "Offensive behavior or nudity" },
            { id: 5, name: "Underage or minor" },
        ]}
    />,
];

const ResourceList: React.FC = (props) => {
    const ReportTypeField = ({ source }: { source: string }) => {
        const record = useRecordContext();
        if (!record) return null;

        if (record[source] != null) {
            const text = reportToString(record[source]);
            return <span>{text}</span>;
        }

        return <span>-</span>;
    };

    const reportToString = (type: Number) => {
        switch (type) {
            case 0:
                return "Fake profile";
            case 1:
                return "Scam";
            case 2:
                return "Sexual services";
            case 3:
                return "Sale or mention of drugs";
            case 4:
                return "Offensive behavior or nudity";
            case 5:
                return "Underage or minor";
            default:
                return `${type}`;
        }
    };

    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <DateField source="createdAt" />
                <TextField source="sender" />
                <TextField source="receiver" />
                <ReportTypeField source="type" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

const ResourceEdit: React.FC<EditProps> = (props: EditProps) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="id" disabled />
                <TextInput source="sender" disabled />
                <TextInput source="receiver" disabled />
                <SelectInput
                    source="type"
                    choices={[
                        { id: 0, name: "Fake profile" },
                        { id: 1, name: "Scam" },
                        { id: 2, name: "Sexual services" },
                        { id: 3, name: "Sale or mention of drugs" },
                        { id: 4, name: "Offensive behavior or nudity" },
                        { id: 5, name: "Underage or minor" },
                    ]}
                />
            </SimpleForm>
        </Edit>
    );
};

ResourceList.displayName = `${resourceName}List`;
ResourceEdit.displayName = `${resourceName}Edit`;

export default {
    list: ResourceList,
    edit: ResourceEdit,
    icon,
    options,
} as Omit<ResourceProps, "name">;
