import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ResourceProps,
    Show,
    ShowProps,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
} from "react-admin";

const resourceName = "date";
const options = { label: "Dates" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    // <TextInput source="firstName" />,
];

const ResourceList: React.FC = (props) => {
    const GenderField = ({ source }: { source: string }) => {
        const record = useRecordContext();
        if (!record) return null;

        if (record[source] != null) {
            const text = genderToString(record[source].type);
            return <span>{text}</span>;
        }

        return <span>-</span>;
    };

    const genderToString = (type: Number) => {
        switch (type) {
            case 0:
                return "Man";
            case 1:
                return "Woman";
            case 2:
                return "Nonbinary";
            default:
                return "-";
        }
    };

    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <DateField source="createdAt" label="Created At" />
                <DateField source="startTime" label="Start Time" />
                <DateField source="endTime" label="End Time" />
                <TextField source="repeat" label="Repeating" />
                <TextField source="description" label="Description" />
                <TextField source="owner" label="Created by" />
                <TextField source="placeName" label="Place name" />
                <TextField source="place" label="Place ID" />
                <TextField source="address" label="Address" />
                <TextField source="lng" label="Longitude" />
                <TextField source="lat" label="Latitude" />
                <TextField source="placeCategory" label="Place category" />
                <TextField source="isDeleted" label="Deleted" />
                <DateField source="boostedDate" label="Boosted Date" />
            </Datagrid>
        </List>
    );
};

const ResourceShow: React.FC<ShowProps> = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <DateField source="createdAt" />
                <DateField source="startTime" />
                <TextField source="description" />
            </SimpleShowLayout>
        </Show>
    );
};

ResourceList.displayName = `${resourceName}List`;
ResourceShow.displayName = `${resourceName}Show`;

export default {
    list: ResourceList,
    show: ResourceShow,
    icon,
    options,
} as Omit<ResourceProps, "name">;
