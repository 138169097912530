import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";

import { HeadingXXLarge } from "baseui/typography";

import {
    Container,
    ErrorText,
    InnerContainer,
    InputWrapper,
    StyledInput,
} from "../commons";

import { useSignIn } from "react-auth-kit";

import { useFormik } from "formik";
import axios, { AxiosError } from "axios";
import { useState } from "react";

function Login(props: any) {
    const [error, setError] = useState("");

    const signIn = useSignIn();
    const navigate = useNavigate();

    const onSubmit = async (values: any) => {
        console.log("Values: ", values);
        setError("");

        try {
            const response = await axios.post(
                process.env.REACT_APP_X_BACKEND_URL + `/login`,
                values,
                {
                    headers: {
                        [process.env.REACT_APP_X_SECRET_KEY_HEADER as string]:
                            process.env.REACT_APP_X_SECRET_KEY as string,
                        [process.env
                            .REACT_APP_X_APPLICATION_ID_HEADER as string]:
                            process.env.REACT_APP_X_APPLICATION_ID as string,
                    },
                }
            );

            signIn({
                token: response.data.data.token.accessToken,
                expiresIn: 3600,
                tokenType: "Bearer",
                authState: { email: values.email },
                refreshToken: response.data.data.token.refreshToken,
            });
            navigate("/panel");
        } catch (err) {
            if (err && err instanceof AxiosError)
                setError(err.response?.data.message);
            else if (err && err instanceof Error) setError(err.message);

            console.log("Error: ", err);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit,
    });

    return (
        <Container>
            <InnerContainer>
                <form onSubmit={formik.handleSubmit}>
                    <HeadingXXLarge>Welcome Back!</HeadingXXLarge>
                    <ErrorText>{error}</ErrorText>
                    <InputWrapper>
                        <StyledInput
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            placeholder="Email"
                            clearOnEscape
                            size="large"
                            type="email"
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <StyledInput
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            placeholder="Password"
                            clearOnEscape
                            size="large"
                            type="password"
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Button
                            size="large"
                            kind="primary"
                            isLoading={formik.isSubmitting}
                        >
                            Login
                        </Button>
                    </InputWrapper>
                </form>
            </InnerContainer>
        </Container>
    );
}

export { Login };
