import * as React from "react";
import { forwardRef } from "react";
import { AppBar, Layout, UserMenu, useLogout } from "react-admin";
import { Button } from "baseui/button";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "baseui/icon";

// It's important to pass the ref to allow BaseUI to manage the keyboard navigation
const MyLogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const navigate = useNavigate();

    const handleClick = () => {
        logout();
        navigate("/login");
    };

    return (
        <Button
            onClick={handleClick}
            ref={ref}
            // It's important to pass the props to allow BaseUI to manage the keyboard navigation
            {...props}
        >
            Logout <ArrowRight />
        </Button>
    );
});

const MyUserMenu = () => (
    <UserMenu>
        <MyLogoutButton />
    </UserMenu>
);

const MyAppBar = () => <AppBar userMenu={<MyUserMenu />} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout;
