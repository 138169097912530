import React from "react";
import {
    Datagrid,
    DateField,
    List,
    ResourceProps,
    TextField,
    TextInput,
} from "react-admin";

const resourceName = "places";
const options = { label: "Places" };
const icon = undefined;

const filters = [
    <TextInput key="q" source="q" label="Search" alwaysOn />,
    <TextInput source="type" />,
];

const ResourceList: React.FC = (props) => {
    return (
        <List {...props} filters={filters}>
            <Datagrid>
                <DateField source="createdAt" label="Created At" />
                <TextField source="totalDatesCount" label="Number of dates" />
                <TextField source="name" label="Place name" />
                <TextField source="id" label="Place ID" />
                <TextField source="placeId" label="Google Place ID" />
                <TextField source="vicinity" label="Address" />
                <TextField source="location.coordinates[0]" label="Longitude" />
                <TextField source="location.coordinates[1]" label="Latitude" />
                <TextField source="type" label="Place category" />
                <TextField source="rating" label="Stars" />
                <TextField source="priceLevel" label="Price level" />
            </Datagrid>
        </List>
    );
};

ResourceList.displayName = `${resourceName}List`;

export default {
    list: ResourceList,
    icon,
    options,
} as Omit<ResourceProps, "name">;
